import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import {
  DocumentReference,
  EnrichFirestore,
  Query,
} from "./EnrichFirestoreTypes";
import { Observable } from "rxjs";
import { useEffect, useState } from "react";
import { useOnPromise } from "./useOnPromise";
import { EnrichCloudFunction } from "./EnrichFunctionsType";
import { FunctionTypes } from "./FunctionTypes";
import { FirestoreCollectionPathTypes } from "./FirestoreTypes";

export type WithID<T> = T & { id: string };

export const firestore = () =>
  firebase.firestore() as Omit<firebase.firestore.Firestore, "collection"> &
    EnrichFirestore<FirestoreCollectionPathTypes>;

export const functions = () =>
  firebase.functions() as Omit<firebase.functions.Functions, "httpsCallable"> &
    EnrichCloudFunction<FunctionTypes>;

export function collectionDataWithId<T>(query: Query<T>) {
  return new Observable<WithID<T>[]>((subscriber) => {
    const sub = query.onSnapshot({
      error: (e) => subscriber.error(e),
      next: (snapshot) =>
        subscriber.next(
          snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        ),
    });
    return {
      unsubscribe() {
        sub();
      },
    };
  });
}

export function docDataWithId<T>(doc: DocumentReference<T>) {
  return new Observable<WithID<T>>((subscriber) => {
    const sub = doc.onSnapshot({
      error: (e) => subscriber.error(e),
      next: (snapshot) =>
        subscriber.next({
          id: doc.id,
          ...snapshot.data()!,
        }),
    });
    return {
      unsubscribe() {
        sub();
      },
    };
  });
}

export function useDoc<T>(doc: DocumentReference<T>) {
  const [state, setState] = useState<T>({} as T);
  useEffect(() => {
    return doc.onSnapshot((d) => setState(d.data()!));
  }, [doc]);
  const { onClick: save, loading: saving } = useOnPromise(async (value?: T) => {
    await doc.set(value ?? state, { merge: true });
  });
  return {
    state,
    setState,
    save,
    saving,
  };
}

export const currentUser$ = new Observable<firebase.User | null>(
  (subscriber) => {
    const sub = firebase.auth().onAuthStateChanged(subscriber);
    return {
      unsubscribe() {
        sub();
      },
    };
  }
);
