import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { CssBaseline, GeistProvider } from "@geist-ui/react";
import firebase from "firebase/app";
import "firebase/database";
import reportWebVitals from "./reportWebVitals";

const firebaseConfig = {
  apiKey: "AIzaSyA6mz-6Hl4vEL2IvSfSMiHZ-SEpZQA2fvw",
  authDomain: "echo-status.firebaseapp.com",
  projectId: "echo-status",
  storageBucket: "echo-status.appspot.com",
  messagingSenderId: "502739035324",
  appId: "1:502739035324:web:64a5fbf07953008c9e1cb9",
  measurementId: "G-3461K7QSV9",
};

firebase.initializeApp(firebaseConfig);

firebase.firestore().settings({ ignoreUndefinedProperties: true });

if (window.location.hostname === "localhost") {
  firebase.auth().useEmulator("http://localhost:9099");
  firebase.firestore().useEmulator("localhost", 8080);
  firebase.functions().useEmulator("localhost", 5001);
  firebase.database().useEmulator("localhost", 9000);
}

firebase.firestore().enablePersistence().catch();

ReactDOM.render(
  <React.StrictMode>
    <GeistProvider>
      <CssBaseline />
      <Suspense fallback={null}>
        <App />
      </Suspense>
    </GeistProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
