import { Link, useHistory, useParams } from "react-router-dom";
import {
  AutoComplete,
  Breadcrumbs,
  Button,
  ButtonGroup,
  Grid,
  Input,
  Modal,
  Page,
  Select,
  Text,
  Textarea,
  Tooltip,
  useToasts,
} from "@geist-ui/react";
import React, { memo, useMemo, useState } from "react";
import {
  collectionDataWithId,
  firestore,
  functions,
  useDoc,
} from "../functions/firebase";
import { useOnPromise } from "../functions/useOnPromise";
import { Activity } from "@geist-ui/react-icons";
import ExecutionHistoryTable from "../components/ExecutionHistoryTable";
import { Task } from "../functions/FirestoreTypes";
import useObservable from "../functions/useObservable";
import { HomeBreadcrumb } from "./ProjectsPage";

export const templates: { name: string; code: string; url?: string }[] = [
  {
    name: "HTTP request",
    code: `async (urlString) => {
    const response = await fetch(urlString);
    if (!response.ok) {
      throw new Error(response.statusText)
    }
}`,
  },
  {
    name: "TLS Cert Expiration",
    code: `async (domain) => {
    if (domain.includes('://')) throw new Error('Domain should not have scheme like https://')
    const result = await sslChecker(domain);
    if (!result.valid) throw new Error('Your cert is no longer valid')
    if (result.daysRemaining < 30) throw new Error(\`Your cert is expiring in \${result.daysRemaining} days\`)
    console.log(\`Cert will expire in \${result.daysRemaining} days\`)
}`,
  },
  {
    name: "TCP Port ping",
    url: "tcp://1.1.1.1:22",
    code: `async (urlString) => {
    const url = new URL(urlString);  
    const port = url.port ? parseInt(url.port) : url.protocol === 'https:' ? 443 : 80;
    const host = url.hostname;
    const connected = await tcpPingPort(host, port);
    if (!connected.online) {
      throw new Error("Can not connect to " + host + ":" + port);
}`,
  },
  {
    name: "Exec SSH command",
    url: "Private Key PEM goes here",
    code: `async (privateKey) => {
  const result = await sshExec('df -h', {host: 'x.x.x.x', port:22, username: 'root', privateKey})
  const disk = result.split('\\n').find(a => a.includes('/dev/sda1')).match(/[^\\s,]+/g)[4]
  if (disk > '70%') {
    throw new Error('Disk is almost full')
  }
}`,
  },
];

const SlackAutoComplete = (props: {
  projectId: string;
  taskId: string;
  value: string;
  onChange: (value: string) => void;
}) => {
  const [, setToast] = useToasts();
  const result = useObservable(
    useMemo(
      () =>
        collectionDataWithId(
          firestore()
            .collection("projects")
            .doc(props.projectId)
            .collection("tasks")
            .where("enabled", "==", true)
        ),
      [props.projectId]
    )
  );
  const options = useMemo(() => {
    if (result?.data == null) {
      return [];
    }
    const slacks = result.data
      .filter((s) => s.id !== props.taskId && s.slackWebhook != null)
      .map((doc) => ({
        label: `${doc.name} - ${doc.slackWebhook!}`,
        value: doc.slackWebhook!,
      }));
    if (slacks.length === 0) {
      return [];
    }
    if (slacks.slice(1).every((a) => a.value === slacks[0].value)) {
      return [
        {
          label: slacks[0].value,
          value: slacks[0].value,
        },
      ];
    }
    return slacks;
  }, [result?.data, props.taskId]);
  return (
    <>
      <AutoComplete
        placeholder="Slack Webhook URL"
        width="100%"
        value={props.value}
        onChange={props.onChange}
        options={options}
      />
      <Tooltip text={"Send Test Notification"} w={"190px"}>
        <Button
          iconRight={<Activity />}
          auto
          scale={0.8}
          ml={1}
          onClick={async () => {
            if (!props.value) {
              return;
            }
            setToast({ type: "default", text: "Sending test message..." });
            try {
              await functions().httpsCallable("testSlack")({
                webhook: props.value,
              });
              setToast({ type: "success", text: "Test message sent" });
            } catch (e) {
              setToast({ type: "error", text: (e as Error).message });
            }
          }}
        />
      </Tooltip>
    </>
  );
};

const TestButton = memo((props: { code: string; url: string }) => {
  const [, setToast] = useToasts();
  const [modalView, setModalView] = useState<string>();
  const test = useOnPromise(async () => {
    const result = (
      await functions().httpsCallable("preCheck")({
        code: props.code,
        url: props.url,
      })
    ).data;
    const text =
      [result.log, result.error].filter(Boolean).join("\n") || result.type;
    setToast({
      type: result.type === "success" ? "success" : "error",
      text,
      delay: 5 * 1000,
      actions: [
        {
          name: "View",
          handler: () => {
            setModalView(text);
          },
        },
      ],
    });
  });
  return (
    <>
      <Button width="100%" {...test}>
        Test
      </Button>
      <Modal visible={!!modalView} onClose={() => setModalView(undefined)}>
        <Modal.Subtitle>Result</Modal.Subtitle>
        <Modal.Content>
          <pre>{modalView}</pre>
        </Modal.Content>
      </Modal>
    </>
  );
});

const TaskDetailPage = () => {
  const { projectId, taskId } =
    useParams<{ projectId: string; taskId: string }>();
  const projectDoc = useMemo(
    () => firestore().collection("projects").doc(projectId),
    [projectId]
  );
  const projectDetail = useDoc(projectDoc);
  const taskDoc = useMemo(
    () => projectDoc.collection("tasks").doc(taskId),
    [projectDoc, taskId]
  );
  const [, setToast] = useToasts();
  const taskDetail = useDoc<Task>(taskDoc);
  return (
    <Page padding={0}>
      <Breadcrumbs mb={2}>
        <HomeBreadcrumb />
        <Link to="/projects">
          <Breadcrumbs.Item>Projects</Breadcrumbs.Item>
        </Link>
        <Link to={`/projects/${projectId}`}>
          <Breadcrumbs.Item>{projectDetail.state.name}</Breadcrumbs.Item>
        </Link>
        <Breadcrumbs.Item>{taskDetail.state.name}</Breadcrumbs.Item>
      </Breadcrumbs>
      <Grid.Container gap={1}>
        <Grid xs={24} md={18} mt={1}>
          <Input
            width="100%"
            label="Task Name"
            value={taskDetail.state.name ?? ""}
            onChange={(e) =>
              taskDetail.setState((p) => ({ ...p, name: e.target.value }))
            }
          />
        </Grid>
        <Grid xs={24} md={6} mt={1}>
          <Button
            scale={0.8}
            width="100%"
            type={"success"}
            loading={taskDetail.saving}
            onClick={async () => {
              await taskDetail.save({ ...taskDetail.state, enabled: true });
              setToast({ type: "success", text: "Task saved" });
            }}
          >
            Save
          </Button>
        </Grid>
      </Grid.Container>
      <Grid.Container gap={1}>
        <Grid mt={1} md={6} xs={24}>
          <Select
            width="100%"
            value={taskDetail.state.frequency}
            onChange={(value) =>
              taskDetail.setState((p) => ({ ...p, frequency: value as any }))
            }
            placeholder="Choose run frequency"
          >
            <Select.Option value="every minute">Every minute</Select.Option>
            <Select.Option value="every 10 minutes">
              Every 10 minutes
            </Select.Option>
            <Select.Option value="every hour">Every hour</Select.Option>
            <Select.Option value="every day">Every day</Select.Option>
          </Select>
        </Grid>
        <Grid mt={1} xs={24} md={18}>
          <Textarea
            rows={1}
            scale={0.8}
            placeholder="Input"
            // label="Input"
            width="100%"
            value={taskDetail.state.input ?? ""}
            onChange={(e) =>
              taskDetail.setState((p) => ({ ...p, input: e.target.value }))
            }
          />
        </Grid>
      </Grid.Container>
      <Grid.Container gap={1}>
        <Grid
          xs={24}
          md={4}
          mt={1}
          direction={"column"}
          justify={"space-between"}
        >
          <ButtonGroup vertical width="100%" margin={0}>
            {templates.map((template) => (
              <Button
                key={template.name}
                onClick={() => {
                  taskDetail.setState((p) => ({
                    ...p,
                    command: {
                      type: "custom",
                      code: template.code,
                    },
                    input: p.input || (template.url ?? ""),
                  }));
                }}
              >
                {template.name}
              </Button>
            ))}
          </ButtonGroup>
          <ButtonGroup vertical width="100%" margin={0} mt={1}>
            <TestButton
              code={taskDetail?.state?.command?.code!}
              url={taskDetail?.state?.input!}
            />
            <Button width="100%">
              <a target="_blank" rel="noreferrer" href="https://reily.app/echo">
                Documentation
              </a>
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid xs={24} md={20} mt={1}>
          <Textarea
            width="100%"
            height="300px"
            placeholder="Code to run..."
            value={taskDetail.state.command?.code ?? ""}
            onChange={(e) =>
              taskDetail.setState((p) => ({
                ...p,
                command: {
                  type: "custom",
                  code: e.currentTarget.value,
                },
              }))
            }
          />
        </Grid>
      </Grid.Container>
      <Grid.Container gap={1}>
        <Grid mt={1} md={6} xs={24}>
          <Select
            width="100%"
            value={taskDetail.state.notifyOn}
            onChange={(value) =>
              taskDetail.setState((p) => ({ ...p, notifyOn: value as any }))
            }
            placeholder="Choose run frequency"
          >
            <Select.Option value="state-changed">
              Notify on state change
            </Select.Option>
            <Select.Option value="every-failed">
              Notify on every fail run
            </Select.Option>
          </Select>
        </Grid>
        <Grid mt={1} xs={24} md={18}>
          <SlackAutoComplete
            taskId={taskId}
            projectId={projectId}
            value={taskDetail.state.slackWebhook ?? ""}
            onChange={(e) =>
              taskDetail.setState((p) => ({
                ...p,
                slackWebhook: e,
              }))
            }
          />
        </Grid>
      </Grid.Container>
      <Text h3 mt={3}>
        Histories
      </Text>
      <ExecutionHistoryTable projectId={projectId} taskId={taskId} />
      <Text h3 mt={3}>
        Danger Zone
      </Text>
      <DeleteProjectButton projectId={projectId} taskId={taskId} />
    </Page>
  );
};

const DeleteProjectButton = ({
  projectId,
  taskId,
}: {
  projectId: string;
  taskId: string;
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const history = useHistory();
  const onDelete = useOnPromise(async () => {
    await firestore()
      .collection("projects")
      .doc(projectId)
      .collection("tasks")
      .doc(taskId)
      .set({ enabled: false }, { merge: true });
    setShowDeleteModal(false);
    history.replace(`/projects/${projectId}`);
  });
  return (
    <>
      <Button type={"error"} ghost onClick={() => setShowDeleteModal(true)}>
        Delete
      </Button>
      <Modal
        width="35rem"
        visible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      >
        <Modal.Title>Danger</Modal.Title>
        <Modal.Subtitle>This can not be undone</Modal.Subtitle>
        <Modal.Content>
          <p>Are you sure you want to delete this task?</p>
        </Modal.Content>
        <Modal.Action passive onClick={() => setShowDeleteModal(false)}>
          Cancel
        </Modal.Action>
        <Modal.Action type={"error"} {...onDelete}>
          Delete
        </Modal.Action>
      </Modal>
    </>
  );
};

export default TaskDetailPage;
